:global([class$="indicatorContainer"]) {
  color: #000000 !important;
}
:global([class$="indicatorContainer"] > svg) {
  height: 15px !important;
  width: 15px !important;
}
:global([class$="control"]) {
  height: 30px !important;
  min-height: 30px !important;
  border-radius: 0px !important;
}
:global([class$="indicatorSeparator"]) {
  display: none !important;
}
.prContainer {
  width: 100%;
  border-radius: 0% !important;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
}

.prTopContainer {
  width: 100%;
  border-radius: 0% !important;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
}

.prProjectContainer {
  width: 14.5%;
  border-radius: 0% !important;
  font-size: 14px;
  margin-right: 2%;
  border: 1px solid hsl(0, 0%, 80%) !important;
  max-height: 240px;
}

.prContainerView {
  max-height: 190px !important;
}

.prCardContainer {
  width: 83.5%;
  border-radius: 0% !important;
  font-size: 14px;
}

.prRag {
  position: absolute;
  top: -0.1em;
  left: 0;
  bottom: 0;
  width: 3%;
  background-color: green;
}

.catContainer {
  width: 19%;
  border-radius: 0% !important;
  font-size: 14px;
  margin-right: 1%;
  display: flex;
  flex-wrap: wrap;
}

.catRag {
  width: 7%;
  margin-top: 1%;
  margin-right: 0.5%;
  background-color: green;
}

.cardsContainer {
  margin-top: 1%;
  width: 93%;
}

.prContent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.hLine {
  border-bottom: 2px dashed #d3d3d3;
  width: 84%;
  display: flex;
  padding-top: 20px;
  margin-left: 16%;
  margin-bottom: 2%;
}

.prDates {
  margin-left: 50px;
  font-size: 12px;
}
.prAuthor {
  font-size: 12px;
}

.prBody {
  width: 100%;
  margin-top: -2%;
}
.validClass {
  height: 40px;
}
.title {
  height: 40px;
  position: relative;
  /* width: 235px; */
  /* padding-right: 5px; */
  font-size: 12px;
}
.highlight {
  animation: shake 0.5s ease-in-out;
  border: none;

  box-shadow: 0 0 10px red; /* Narrow box shadow */
}
.prLabel {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: 600;
  font-size: 12px;
}
.prLabelEditor {
  font-weight: 600;
  font-size: 12px;
}
.prLabelEditorEdit {
  margin-top: 3em;
}

.prTitleTextDisabled {
  pointer-events: none;
  height: 40px;
  position: relative;
  width: 235px;
  font-size: 12px;
}

.prView {
  display: flex;
}

.prValue {
  padding-bottom: 2px;
  margin-left: 5px;
  font-weight: 400;
  font-size: 12px;
}

.prLabelExCol {
  margin-left: 30px;
  padding-top: 9px;
  font-style: normal;
}

.prLabelExExpand {
  margin-left: 30px;
  padding-top: 5px;
  font-style: normal;
}

.prValue {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  padding-left: 0px;
  font-style: normal;
}

.iconTitle {
  color: #a5a5a5;
  margin-top: 2px;
  margin-left: 5px;
  font-size: 20px;
  font-weight: 100;
  position: absolute;
}

.iconColCol {
  color: #000000;
  margin-right: 5px;
  font-size: 30px;
  font-weight: 100;
  cursor: pointer;
}
.prLabelColEx {
  margin-top: 0.5em;
  font-weight: 600;
  font-size: 12px;
  width:15%;
}
.prActions {
  margin-top: 1em;
}
.prActionsView {
  font-size: 12px;
  margin-top: 1em;
}
.iconContainer {
  margin-left: 8em;
}
.iconDelete {
  color: #ff726f;
  font-size: 20px;
  font-weight: 100;
  cursor: pointer;
}
.prId {
  color: #808080;
  font-size: 12px;
  display: flex;
  float: left;
  padding-top: 0%;
  padding-bottom: 3%;
  white-space: nowrap;
}
@media screen and (max-width: 1600px) {
  /* For Dell */
  .prProjectContainer {
    max-height: 245px;
  }
  .iconContainer {
    margin-left: 4em;
  }
  .prActions {
    margin-top: 0.2em;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1850px) {

  .iconContainer
  {
    margin-left: 6em !important;
  }
}
@media screen and (max-width: 1280px) {
  .crId {
    font-size: 6px !important;
  }
  .crContainer {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1200px) {
  /* For Surface */
  .prProjectContainer {
    max-height: 260px;
  }
  .iconContainer {
    margin-left: 2.5em;
  }
  .prActions {
    margin-top: 0.2em;
  }
  .prContainerView {
    max-height: 195px !important;
  }
  .iconColEx {
    font-size: 60px !important;
  }
}

@media screen and (min-width: 1900px) {
  /* For Large Monitor */
  .prProjectContainer {
    max-height: 260px;
  }
  .title {
    /* width: 260px !important; */
  }
  .iconContainer {
    margin-left: 8em;
  }
}
@media (min-width: 1800px) and (max-width: 1900px) {
  /* For Surface Pro */

  .iconContainer {
    margin-left: 7em !important;
  }
}
.iconColEx {
  color: #000000;
  margin-top: -2px;
  margin-left: 5px;
  font-size: 40px;
  font-weight: 100;
  cursor: pointer;
}

.prNoContainer {
  width: 10%;
  border-radius: 0% !important;
  border: 1px solid hsl(0, 0%, 80%) !important;
  display: flex;
  margin-top: 2%;
  margin-bottom: 2%;
}

.prNoRag {
  width: 5%;
  background-color: green;
  float: left;
}

.prNoBody {
  height: 10px;
  width: 100%;
}

.prNoContent {
  margin-left: 2%;
  float: left;
  padding: 10px;
}

.prNoText {
  padding: 5px;
  border: 1px solid #d3d3d3;
  margin: 10px;
}

.titleSpan {
  position: absolute;
  opacity: 0;
}

.cardContainer {
  width: 19%;
  border-radius: 0% !important;
  font-size: 14px;
  float: left;
  height: 300px;
  margin-right: 1%;
}

.commentIcon {
  margin-left: -3px;
  margin-top: 2px;
  cursor: pointer;
}

.prNameView {
  word-break: break-word;
  height: 4em;
}

@media print {
  .prActionsView,
  .prLabelEditor,
  .prAuthor {
    display: none !important;
  }
  .prContainerView {
    max-height: 210px !important;
  }
  .prNameView {
    height: 7em !important;
  }
  .pageBreakBefore {
    page-break-before: always;
  }
  .pageBreakAfter {
    page-break-after: always;
  }
  .printHead {
    display: flex;
    justify-content: flex-start;
    font-size: 18px;
    color: red;
    padding: 10px 10px 10px 10px;
  }
}
