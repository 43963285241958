/* confirmationPopup.module.css */
.confirm :global .modal-content {
  margin-top: 60% !important;
  height: 35vh !important;
  padding: 10px 10px 10px 10px;
}
.cofWarning {
  color: #ff4500;
  font-size: 40px !important;
  margin-bottom: 8px;
  margin-right: 10px;
}
.confirmationModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  max-width: 90%;
}

.confirmationOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.confirmationHeader {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.confirmationMessage {
  font-size: 14px;
  margin-bottom: 20px;
}

.confirmationButtons {
  display: flex;
  justify-content: flex-end;
}

.confirmationButton {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #555;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.confirmationButtonCancel {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #ccc;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.confirmationButton:hover {
  background-color: red;
}
