.tdNoteNo {
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  width: 5%;
}
.tdNoteId {
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  width: 10%;
}
.tdNoteTitle {
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  width: 20%;
}

.noteIcon {
  cursor: pointer;
}

.notesTable {
  width: 99%;
  border: 1px solid;
  border-color: #000000 !important;
}
.notesTableDelete {
  width: 3%;
  border: 1px solid;
  border-color: #000000 !important;
}
.notesDesc {
  width: 100%;
  height: 105px;
  padding-top: 5px;
  overflow: hidden;
}
.iconNoteTitle {
  color: #a5a5a5;
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: 100;
}
.iconNoteDesc {
  color: #a5a5a5;
  margin-bottom: 156px;

  font-size: 20px;
  font-weight: 100;
}
.notesDescArea {
  margin-right: 15px;
  height: 90px;
  overflow: auto;
  resize: none;
  word-wrap: break-word;
  width: 90%;
  max-width: 80%;
  font-size: 12px;
  text-align: left;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
}
.tdNotesDesc {
  width: 60%;
}

.tdNoteAddDelete {
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  width: 7%;
}
.tdNoteAdd {
  padding-right: 0px;
  width: 50%;
  float: left;
}
.tdNoteDelete {
  width: 50%;
  float: left;
}
.tdNoteLink {
  width: 5%;
}
.iconAdd {
  color: #000000;
  font-size: 25px;
  margin-top: 2px;
  font-weight: 100;
  cursor: pointer;
}
.iconDelete {
  color: #ff726f;
  font-size: 25px;
  font-weight: 100;
  margin-top: 20px;
  cursor: pointer;
}
.iconDeleteDisabled {
  color: #555;
  font-size: 25px;
  font-weight: 100;
  margin-top: 20px;
  opacity: 0.3;
}
.iconLink {
  color: #555;
  font-size: 30px;
  font-weight: 100;

  margin-top: 5px;
  cursor: pointer;
}

.noteTitle {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;

  padding-left: 0px;
  font-style: normal;
}
.notesBox {
  width: 100%;
  padding-left: 0.5%;
  display: flex;
}
.alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 31px;
  color: #ff007f;
  font-weight: bold;
  background-color: #fff;
  padding: 20px;
  border: 2px solid #000;
  z-index: 9999;
}
.notesArea {
  padding-top: 1%;
  padding-left: 2.5%;
}

.notesBoxPlus {
  width: 20%;
  float: left;
  display: flex;
  cursor: pointer;
}
.notesPrintHead {
  width: 20%;
  float: left;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  page-break-before: always;
  padding: 10px 10px 10px 10px;
}
.addNoteSpan {
  padding-left: 2%;
  color: #2596be;
  font-weight: 600;
  font-size: 16px;
  padding-top: 4px;
}
.notesBoxTable {
  width: 94%;
  float: left;
}

.selNTd {
  background-color: yellow; /* Adjust the color as needed */
  padding: 0 2px; /* Optional: adds some padding around the text */
  border-radius: 2px; /* Optional: adds rounded corners to highlight */
}

.notesBoxTableDelete {
  width: 1%;
  float: left;
  padding-left: 0%;
  display: flex;
}
.notesTableDelete {
  border: none;
  border-top: none;
  border-bottom: none;
  border-bottom-width: 0px;
  display: flex;
}
.selNTr td {
  background-color: #FFF9C4;
  padding: 0 2px;
  border-radius: 2px;
}
:global(.table) > :not(caption) > * > * {
  border-bottom-width: 0px !important;
}
.td {
  border-bottom-width: 0px !important;
}

.notesBoxDelete {
  width: 2%;
  height: 220px;
  padding-left: 1%;
  vertical-align: middle;
  padding-top: 40%;
  /* margin-left: -3.5%; */
  float: left;
}
.commentIconView {
  margin-left: -3px;
  margin-top: 2px;
  background-blend-mode: #d3d3d3;
  pointer-events: none;
}
.commentIcon {
  margin-left: -3px;
  margin-top: 2px;
  cursor: pointer;
}
.plusDisabled {
  opacity: 0.3;
}
.notesAreaView {
  padding-top: 1%;
  padding-left: 0.5%;
}
@media screen {
  .notesPrintHead {
    display: none !important;
  }
  .notesAreaView {
    opacity: 0.5;
  }
}
@media print {
  .notesBoxPlus,
  .tdNoteLink {
    display: none !important;
  }
  .pageBreakBefore {
    page-break-before: always;
  }
  /* .notesAreaView {
    page-break-before: always;
  } */
  .pageBreakAfter {
    page-break-after: always;
  }
}
