body {
  overflow-y: auto;
}

/* App clases*/
.header {
  /* background: #04a4b8; */

  display: flex;
  /* align-items: center; */
}
.subContainer {
  display: flex;
  align-items: center; /* Aligns items vertically center */
}

.headerImage {
  width: 10%;
  background: #484c57;

  padding-left: 2%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}
/* Container with the arrow on the right */
.arrowContainer {
  display: flex;
  align-items: center;

  background-color: #484c57;
  color: #fff;
  height: 54px;
  position: relative;
  padding-left: 20px;
  width: 100px;
  /* margin-right: 5em; */
  padding-top: 2px;
}

/* Arrow shape on the right */
.arrowContainer::after {
  content: "";
  position: absolute;
  right: -15px; /* Adjusted based on container width */
  top: 2px !important;
  width: 0;
  height: 0;
  border-left: 15px solid #484c57; /* Arrow point */
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
}

/* Text styling */

.text {
  background-color: #484c57;
  color: #fff;
  display: inline-block;
  padding-left: 4px;
}
.arrow {
  border-style: dashed;
  border-color: transparent;
  border-width: 0.2em;
  display: -moz-inline-box;
  display: inline-block;
  /* Use font-size to control the size of the arrow. */
  font-size: 100px;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: middle;
  width: 0;
  background-color: #fff; /* change background color acc to bg color */
  border-left-width: 0.2em;
  border-left-style: solid;
  border-left-color: #484c57;
  left: 0.25em;
}

/* Text styling */
.arrowText {
  font-family: Calibri !important;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.arrowText h1 {
  font-size: 12px;
  line-height: 1;
  margin: 0;
}
.arrowText p {
  font-size: 8px;
  color: #b0b0b0;
  margin: 0;
  letter-spacing: 0.5px;
  margin-top: 2px;
}

.headerSubText {
  font-weight: 400;
  font-size: 14px;
  padding-left: 37%;
}

.profile {
  padding-right: 2em;
  display: flex;
  background-color: #04a4b8;
}
.introContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.introText {
  font-size: 24px;
  text-align: center;
  color: #333;
}
.headerText {
  background: #04a4b8;
  color: #ffffff;
  font-size: 15px;
  flex-grow: 1;
  display: flex;
  padding-left: 5em;
}
/* .logoutIcon
{
  color: #FF0000;
  margin-left: 20px;
  cursor: pointer;
  font-size: 26px;
} */
.protected {
  background-color: #fff;
  border-radius: 5px;
  color: red;
  padding: 0 10px; /* Adjusted */
  margin-left: 20%;
  font-weight: 700;
  display: inline-flex;
  margin-top: 15px;
}
.headerLine {
  margin-top: 10px;
}
.userRole {
  font-size: 16px;
  color: yellow;
  margin-left: 10px; /* Added margin to space out from name */
}
.profileContainer {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between profile card and logout */
}

.profileCard {
  display: flex;
  align-items: center;
  border: 1px groove #fff;
  padding: 8px 12px;
  border-radius: 5px;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.3);
}
.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modalContent {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
}

.imageContainer {
  position: relative;
  width: 100px;

  margin-bottom: 20px;
  margin: 0 auto 20px 0;
}

.image {
  width: 80px;
  height: 60px;
}

.iconContainer {
  margin-bottom: 20px;
}

.icon {
  color: #808080;
  font-size: 60px !important;
}
.modalText {
  margin-bottom: 20px;
  text-align: center;
}

.loginButton {
  width: 100%;
  background-color: #04a4b8 !important;
  text-transform: capitalize !important;
  background-color: #186d90 !important;
  height: 50px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logoutIcon {
  color: #fff;
  cursor: pointer;

  font-size: 1.8em !important;
}

/* .profileCard {
  display: flex;
  align-items: center;
  padding: 12px;
} */

.userPhoto {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 20px;
}
.leftNavBar {
  width: 5%;
  position: fixed;
  padding-top: 5%;
}

/* .userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  margin-left: 10px;
} */

.userName {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 4px; /* New */
}

@media screen and (max-width: 1380px) {
  /* For Dell */
  .protected {
    width: 12% !important;
  }
}
@media screen and (max-width: 1600px) {
  /* For Surface */
  .protected {
    width: 25% !important;
  }
}
/* Container holding both sections */
.container {
  background-color: #484c57;
  display: flex;
  height: 55px;
  overflow: hidden;
  position: relative;
  width: 150px;
}

/* Left section with the text */
.leftSection {
  align-items: center;
  background-color: #484c57;
  display: flex;
  flex: 2 1;
  /* justify-content: center; */
  padding-left: 10px;
  padding-top: 9px;
}

.text h1 {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
}

.text p {
  color: #b0b0b0;
  font-size: 8px; /* Slightly larger font size */
  letter-spacing: 0.5px;
  margin-top: 2px;
}

/* Right section with the colored triangles */
.rightSection {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 40px solid #04a4b8; /* Cyan triangle width increased */
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  position: absolute;
  right: 0;
}

.triangle::before {
  content: "";
  position: absolute;
  left: 10px; /* Adjusted alignment */
  width: 0;
  height: 0;
  border-left: 20px solid #3cc0d3; /* Lighter cyan triangle */
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
}
