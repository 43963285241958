.topSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.2rem;
}

.topSection input {
  width: 650px;
}
.topSection h2 {
  margin-right: 1rem;
}
.finderIcon {
  color: #333;
  font-size: 40px !important;
  margin-top: 5px;
  margin-right: 20px;
}
.topHeader {
  padding-right: 30px;
  font-size: 42px;
  color: #333;
}
.naContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30%;
  padding-top: 10%;
}

.naMessage {
  background-color: #2596be;
  border-radius: 8px;
  padding: 20px;
  color: white;
}
@media screen and (max-width: 1600px) {
  /* For Dell */
  .bottomSection {
    max-height: 65vh;
  }
  .gridContainer {
    padding-left: 5.5% !important;
  }
  .topHeader {
    font-size: 36px !important;
  }
  .countSection {
    padding-left: 54em !important;
  }
  .topHeaderComment {
    font-size: 36px !important;
  }
  .topHeaderNote {
    font-size: 36px !important;
  }
  .countSectionComment {
    padding-left: 56.5em !important;
  }
  .countSectionNote {
    padding-left: 56.5em !important;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1200px) {
  /* For Surface */
  .container {
    padding-top: 7%;
  }
  .topSection {
    width: 100%;
  }

  .topHeader {
    width: 35%;
  }
  .topHeaderComment {
    width: 35%;
  }
  .topHeaderNote {
    width: 35%;
  }
  .searchBox {
    width: 70%;
  }
  .countSection {
    padding-left: 57rem !important;
  }

  .bottomSection {
    max-height: 65vh;
    width: 99.9%;
  }
  .gridContainer {
    padding-left: 5.5% !important;
  }
  .topHeader {
    font-size: 36px !important;
  }

  .topHeaderComment {
    font-size: 36px !important;
  }
  .topHeaderNote {
    font-size: 36px !important;
  }
  .countSectionComment {
    padding-left: 57rem !important;
  }
  .countSectionNote {
    padding-left: 58rem !important;
  }
  .topSection input {
    width: 550px !important;
  }
}
