.leftNavBar {
  width: 5%;
  padding-top: 5%;
  padding-bottom: -25%;
  position: sticky;
  display: flex;
}
:global([class$="leftNavBar"]) {
  display: none !important;
}
/* .container *::before {
  content: none !important;
} */
.buttons {
  display: flex;
  flex-direction: row;
  width: 23%;
  padding-left: 2%;
}
.btnDisabled {
  opacity: 0.4;
}
.iconFilter {
  color: rgb(255, 255, 255);
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: 100;
  cursor: pointer;
}
.button {
  background-color: #2596be !important;
  border-radius: 10px !important;
  margin-left: 3em !important;
  margin-top: 0.5em !important;
  padding: 10px 12px !important;
  cursor: pointer;
  width: 11em !important;
  font-family: Segoe UI !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}
.columnId {
  cursor: pointer;
}
.homeIcon {
  margin-right: 10px;
  margin-top: -3px;
  color: #0073e6;
  font-size: 25px !important;
  cursor: pointer;
}
.homeIconHead {
  color: #0073e6;
  font-size: 40px !important;
  margin-top: 5px;
  margin-right: 20px;
}
.finderIcon {
  color: #006400;
  font-size: 40px !important;
  margin-top: 5px;
  margin-right: 20px;
}
.finderIconComment {
  color: #800080;
  font-size: 40px !important;
  margin-top: 5px;
  margin-right: 20px;
}
.finderIconNote {
  color: #ffbf00;
  font-size: 40px !important;
  margin-top: 5px;
  margin-right: 20px;
}
.confirmButton {
  background-color: #2596be;
}
.confirmButton:hover {
  background-color: #1e7898;
}
.buttonIcon {
  margin-right: 5px;
  font-size: 20px;
}
.leftSection {
  flex: 1;
  width: 18%;
  padding: 1rem;
  padding-top: 2%;
  background-color: #e0e4e7;
  height: 90vh;
  overflow-y: auto;
  padding-bottom: 2rem;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 82%;
  padding: 1rem;
  padding-top: 3%;
  padding-left: 2%;
  /* background-color: #e9f5f9; */
}
.container {
  width: 100%;
  padding-top: 2%;
  display: flex;
  flex-flow: wrap;
}
:global .slide-pane__overlay {
  z-index: 2 !important;
}
body {
  overflow: hidden !important;
}
.gridContainer {
  width: 99.7%;
  display: flex;
  padding-top: 0%;
  padding-left: 4.5%;
}

.sortIcon {
  margin-left: 5px;
  vertical-align: middle;
  font-size: 20px;
  color: #2596be;
  cursor: pointer;
}
th {
  white-space: nowrap;
  /* width: 200px; */
  padding-right: 30px;
  font-weight: 400 !important;
  font-size: 18px;
}
.searchBox :global([class$="control"]) {
  height: 50px !important;
}
.searchBox {
  margin-top: 10px;
}

.searchControl {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" viewBox="0 0 24 24" fill="none" stroke="%23444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8" /><line x1="21" y1="21" x2="16.65" y2="16.65" /></svg>');
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 40px;
}

.topHeader {
  padding-right: 30px;
  font-size: 42px;
  color: #006400;
}
.topHeaderCopy {
  padding-right: 30px;
  font-size: 42px;
  color: #333333;
}
.topHeaderComment {
  padding-right: 30px;
  font-size: 42px;
  color: #800080;
}
.topHeaderNote {
  padding-right: 60px;
  font-size: 42px;
  color: #ffbf00;
}
th.desc {
  border-bottom: 5px solid rgb(136, 136, 255);
}
.colorBox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 5px;
  position: relative;
  top: 3.5px;
}
.miniColorBox {
  border-radius: 50%;
  height: 10px;
  margin-left: 10px;
  position: relative;
  top: 9px;
  width: 10px;
}

.ragLabel {
  margin-right: 5px;
}
.ragFilter {
  display: flex;
}
th.asc {
  border-top: 5px solid orange;
}

.iconFilterShow {
  font-size: 25px;
  margin-bottom: 5px;
  color: #2596be;
  cursor: pointer;
}
.iconFilter {
  color: #2596be;
  cursor: pointer;
  font-size: 25px;
  margin-bottom: 5px;
}
.iconFilterHide {
  font-size: 25px;
  margin-bottom: 5px;
  color: #2596be;
  opacity: 0.5;
  /* color: #ff726f; */
  pointer-events: none;
}
.bottomSection {
  padding-right: 5px;
  overflow: auto;
  max-height: 70vh;
  width: 99.9%;
}
@media screen and (max-width: 1600px) {
  /* For Dell */
  .bottomSection {
    max-height: 65vh;
  }
  .gridContainer {
    padding-left: 5.5% !important;
  }
  .topHeader {
    font-size: 36px !important;
  }
  .countSection {
    padding-left: 54em !important;
  }
  .topHeaderComment {
    font-size: 36px !important;
  }
  .topHeaderNote {
    font-size: 36px !important;
  }
  .countSectionComment {
    padding-left: 56.5em !important;
  }
  .countSectionNote {
    padding-left: 56.5em !important;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1200px) {
  /* For Surface */
  .container {
    padding-top: 7%;
  }
  .topSection {
    width: 100%;
  }

  .topHeader {
    width: 35%;
  }
  .topHeaderComment {
    width: 35%;
  }
  .topHeaderNote {
    width: 35%;
  }
  .searchBox {
    width: 70%;
  }
  .countSection {
    padding-left: 57rem !important;
  }

  .bottomSection {
    max-height: 65vh;
    width: 99.9%;
  }
  .gridContainer {
    padding-left: 5.5% !important;
  }
  .topHeader {
    font-size: 36px !important;
  }

  .topHeaderComment {
    font-size: 36px !important;
  }
  .topHeaderNote {
    font-size: 36px !important;
  }
  .countSectionComment {
    padding-left: 57rem !important;
  }
  .countSectionNote {
    padding-left: 58rem !important;
  }
  .topSection input {
    width: 650px !important;
  }
}

*:hover::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
}

Table thead {
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
  background-color: #e0e4e7;
  z-index: 1;
}
.lblFilter {
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 5px;
}
.lblFilterDisabled {
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 5px;
  opacity: 0.5;
}
.customSection {
  padding-top: 30px;
}
.filter {
  padding-top: 20px;
}

.filterContent {
  margin-left: 20px;
  padding-top: 10px;
}

.iconColsShow {
  cursor: pointer;
  font-size: 35px !important;
  stroke-width: 0.5;
  color: #008000;
  padding-left: 10px;
  padding-bottom: 2px;
}
.iconColsHide {
  cursor: pointer;
  font-size: 35px !important;
  color: #ff0000;
  padding-left: 10px;
  padding-bottom: 2px;
}
.dateContent {
  padding-top: 2px;
}
.dateContent {
  border: none;
}
.clearFiltersContainer {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  cursor: pointer;
}

.clearFiltersIcon {
  padding-right: 2px;
  font-size: 20px;
  color: #ff0000 !important;
}

.clearFiltersText {
  font-size: 16px;
  font-weight: 600;
  padding-left: 0px;
  color: #006400;
}
.clearFiltersTextComment {
  font-size: 16px;
  font-weight: 600;
  padding-left: 0px;
  color: #800080;
}

.datePicker {
  border: none;
  outline: none;
  width: 120px;
  padding: 10px;
  margin-right: 5px;
}
.datepickerWrapper {
  display: flex;
  align-items: center;
  margin-left: 0px;
}

.datepickerWrapper label {
  margin-right: 10px;
}
.datePicker:disabled {
  background-color: #ebebeb;
}
.lblDate {
  padding: 5px;
  font-weight: 600;
  font-style: italic;
}
.topSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.2rem;
}
.countSection {
  padding-left: 62.5rem;
  font-size: 18px;
  margin-bottom: 2rem;
  font-weight: 600;
  color: #006400;
}
.countSectionComment {
  padding-left: 69rem;
  font-size: 18px;
  margin-bottom: 2rem;
  font-weight: 600;
  color: #800080;
}
.countSectionNote {
  padding-left: 62.8rem;
  font-size: 18px;
  margin-bottom: 2rem;
  font-weight: 600;
  color: #ffbf00;
}
.topSection h2 {
  margin-right: 1rem;
}

.topSection input {
  width: 800px;
}

.grid {
  width: 100%;
  /* border-collapse: collapse; */
  /* border: none !important; */
}

.grid th {
  font-weight: bold;
  text-align: left;
  border-bottom: 2px solid #a9a9a9;
  /* border-right: none; */
  padding: 0.5rem;
}

.grid td {
  text-align: left;
  padding: 0.5rem;
  /* border: none !important; */
  background-color: none;
}
.loaderContainer1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  overflow: hidden !important;
}
.loaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-scroll {
  overflow: hidden;
}
