.linkbox {
  display: flex;
  flex-direction: column;
  background-color: #eaeaea;
  padding: 5px 5px 5px 5px;
  margin-left: 8%;
  margin-top: 10%;

  border: 1px dotted #ccc;

  border-radius: 5px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  position: relative;
  border-radius: 5px;
}

.row::after {
  content: "";
  position: absolute;
  bottom: 0px; /* Adjust the bottom position to set the dotted line distance */
  left: 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #ccc;
}
.txtNote {
  padding: 0 2px 0 5px;
}
.unlocked {
  background-color: #fdfca3;
  cursor: pointer;
}

.lockIcon {
  cursor: pointer;
  font-size: 20px;
  padding: 0 5px 0 0;
}
.cmdModalArea {
  max-width: 95% !important;
  padding-top: 5%;
}
.cmdModalAreaFull {
  width: 100vw;
  max-width: none;
  height: 50%;
  margin: 0;
}
.cmdModalArea :global .modal-content {
  border-radius: 10px;
  border: 1px solid #2596be;
  padding: 10px 10px 10px 10px;
  height: 80vh !important;
  overflow-y: hidden !important;
}
.cmdModalArea :global .modal-header {
  border-bottom: none !important;
  padding-left: 0 !important;
  padding-top: 2% !important;
}
.cmdModalArea :global .btn-close {
  margin-top: -5% !important;
}
:global .modal-body {
  padding: 0 !important;
}
:global .modal-footer {
  border-top: none !important;
}
.topHeaderComment {
  padding-left: 20px;
  font-size: 36px;
  margin-left: 80%;
  width: 100%;
  text-align: center;
  color: #2596be;
}
.topHeaderCC {
  font-size: 36px;
  width: 80%;
  padding-left: 15%;
  text-align: center;
  color: #2596be;
}
.customCircularProgress {
  margin: 10% 0% 0% 40%;
  width: 100px !important;
  height: 100px !important;
  color: purple !important;
}
.iconHead {
  color: #af69ef;
  font-size: 50px !important;
  padding-right: 5px;
}

.btnSave {
  text-transform: none !important;
  color: #fff;
  border: 1px solid #af69ef !important;
  background-color: #af69ef !important;
  border-radius: 10px !important;
  padding: 6px 12px;
  margin-left: 45px !important;
  cursor: pointer !important;
  font-family: inherit !important;
  font-size: 16px !important;
  line-height: 30px !important;
  font-weight: 400 !important;
}

.gridContainer {
  width: 99.7%;
  display: flex;
  padding-top: 0%;
  padding-left: 1%;
}
.container {
  width: 100%;
  padding-top: 1%;
  display: flex;
  flex-flow: wrap;
}
.viewLabel {
  padding-left: 5px;
  padding-right: 5px;
}
.viewOnly {
  opacity: 0.5;
}
.columnView {
  pointer-events: none;
}
.rightSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 82%;
  padding: 1rem;
}
.topSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.2rem;
  width: 90em;
}
.leftSection {
  flex: 1;
  width: 18%;
  padding: 1rem;
  padding-top: 2%;
  background-color: #e0e4e7;
  height: 65vh;
  overflow-y: auto;
  padding-bottom: 2rem;
}
.searchBox :global([class$="control"]) {
  height: 50px !important;
}
.searchBox {
  margin-top: 10px;
  width: 65%;
}
.searchBoxCC {
  margin-top: 10px;
  width: 80%;
}
.columnId {
  cursor: pointer;
}
.linkIcon {
  margin-right: 5px;
  margin-top: -3px;
  color: #0073e6;
  font-size: 25px !important;
  cursor: pointer;
}
.linkPlaceholder {
  margin-right: 5px;
  margin-top: -3px;
  color: #0073e6;
  font-size: 25px !important;
  cursor: pointer;
}
.unLinkIcon {
  margin-right: 5px;
  margin-top: -3px;
  color: #ff726f;
  font-size: 25px !important;
  cursor: pointer;
}
.countSectionNC {
  padding-left: 55rem;
  padding-bottom: 1rem;
  font-size: 16px;
  font-weight: 600;
  color: #800080;
}
.countSectionCC {
  padding-left: 64rem;
  padding-bottom: 1rem;
  font-size: 16px;
  font-weight: 600;
  color: #800080;
}
.highlighted {
  background-color: #ffffcc;
}

.legendContainer {
  background-color: #f5f5f5;
  border-radius: 5px;
  border-radius: 50%;
  border: 1px dotted #000;
  padding: 5px;

  margin-top: 0px;
}

.legend {
  display: flex;
  flex-direction: column;

  padding: 10px;
  padding-bottom: 2px;
}

.legendItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.legendLink,
.legendUnLink {
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
  margin-left: 10px;
  width: 12px;
}
.bottomSection {
  padding-right: 5px;
  overflow: auto;
  max-height: 52vh;
  width: 100%;
}
.legendLink {
  background-color: #fdfca3;
}

.legendUnLink {
  background-color: #ffffff;
}
/* MyComponent.module.css */
.circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 8px;
}



.legendText {
  margin-left: 5px; /* Add left padding to the text */
  text-align: left; /* Align text to the left */
}

/* Custom style for highlighted rows */
.highlighted input[type="checkbox"]:checked {
  background-color: #ff554e;
}

.buttons {
  display: flex;
  flex-direction: row; /* Add this to make the buttons appear in a row */
  width: 23%;
  padding-left: 2%;
}
.btnDisabled {
  opacity: 0.4;
}
@media screen and (max-width: 1600px) {
  /* For Dell */
  .bottomSection {
    max-height: 65vh;
  }
  .searchBox {
    width: 55% !important;
  }
  .topHeaderComment {
    margin-left: 60% !important;
  }
  .countSectionComment {
    padding-left: 46em !important;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1200px) {
  /* For Surface */
  .bottomSection {
    max-height: 65vh;
  }
  .countSectionNC {
    padding-left: 41rem !important;
  }
  .countSectionCC
  {
    padding-left: 40rem !important;
  }
  .searchBox {
    width: 50% !important;
  }
  .topHeaderComment {
    margin-left: 60% !important;
  }
  .countSectionComment {
    padding-left: 44em !important;
  }
}
/* Shared styles for buttons */
.button {
  display: flex;
  align-items: center;

  color: #fff;
  border: none;
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 10px;
  padding: 10px 12px;
  cursor: pointer;
  width: 35%;
}

.confirmButton {
  background-color: #2596be;
}
.confirmButton:hover {
  background-color: #1e7898;
}
.buttonIcon {
  margin-right: 5px;
  font-size: 20px;
}
.resetButton:hover {
  background-color: #ff554e;
}

.resetButton {
  background-color: #ff726f;
  margin-left: 30px;
}

.selectAllCheckbox {
  display: flex;
  align-items: center;
  justify-content: "center";
  font-size: 16px;
  padding-bottom: 0.5rem;
}
.selectAllSpan {
  padding-left: 0.2rem;
}
input[type="checkbox"] {
  cursor: pointer;
}
.tableRowWithBorder {
  border-bottom: 1px dotted #000;
}
