.catContainer {
  width: 100%;
  border-radius: 0% !important;
  font-size: 14px;
}
.catTopContainer {
  width: 100%;
  border-radius: 0% !important;
  font-size: 14px;
}
.arrow {
  z-index: 999;
  color: #ffffff;
  margin-top: 10px;

  font-size: 20px !important;
}

.catProjectContainer {
  width: 14.5%;
  border-radius: 0% !important;
  font-size: 14px;
  float: left;
  margin-right: 2%;
  border: 1px solid hsl(0, 0%, 80%) !important;
}
.catCardContainer {
  width: 83.5%;
  border-radius: 0% !important;
  font-size: 14px;
  float: left;
}
.paletteContainer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.palette {
  font-size: 18px !important;
  color: #fff;
}
.catContainer {
  width: 19%;
  border-radius: 0% !important;
  font-size: 14px;
  float: left;
  display: flex;
  /* height: 230px; */
  margin-right: 1%;
}
.catRag {
  width: 7%;
  margin-top: 1%;
  margin-right: 0.5%;
  z-index: 999;
  float: left;
  cursor: pointer;
  position: relative;
}
.catRagReadOnly {
  width: 7%;
  margin-top: 1%;
  margin-right: 0.5%;
  /* background-color: #008000; */
  float: left;
}

.cardsContainer {
  margin-top: 1%;
  width: 93%;
  z-index: 0;
  position: relative;
}
.catContent {
  float: left;
  margin-left: -2%;
}
.hLine {
  border-bottom: 2px dashed #d3d3d3;
  width: 84%;

  padding-top: 300px;
  margin-left: 16%;
  margin-bottom: 2%;
}
.catDates {
  margin-left: 50px;
}
.catBody {
  height: 200px;
  width: 100%;
  margin-top: -2%;
}
.title {
  height: 40px;
  position: relative;
  width: 235px;
  font-size: 12px;
}
:global([class$="indicatorContainer"]) {
  color: #000000 !important;
}
:global([class$="indicatorContainer"] > svg) {
  height: 15px !important;
  width: 15px !important;
}
:global([class$="control"]) {
  height: 30px !important;
  min-height: 30px !important;
  border-radius: 0px !important;
}
:global([class$="indicatorSeparator"]) {
  display: none !important;
  /* color:#000000 !important;
  height:1em !important;
  width:1em !important; */
}
.catLabel {
  padding-bottom: 2px;
  font-weight: 600;
  font-size: 12px;
}
.catLabelExCol {
  margin-left: 30px;
  padding-top: 9px;
  font-style: normal;
}
.catValue {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  padding-left: 0px;

  font-style: normal;
}
.iconTitle {
  color: #a5a5a5;
  margin-top: 2px;
  margin-left: 5px;
  font-size: 20px;
  font-weight: 100;
  position: absolute;
}
.iconColEx {
  color: #000000;
  margin-top: 2px;
  margin-right: 5px;
  font-size: 20px;
  font-weight: 100;
  position: absolute;
}
.iconColEx {
  margin-top: 10px;
}
.titleSpan {
  position: absolute;
  opacity: 0;

  /* word-wrap:break-word;*/
}
.cardContainer {
  width: 19%;
  border-radius: 0% !important;
  font-size: 14px;
  float: left;
  height: 300px;
  margin-right: 1%;
}
.commentIcon {
  cursor: pointer;
}
.plusDisabled {
  opacity: 0.3;
}
.tooltipText {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
}

.commentIconView {
  margin-left: -3px;
  margin-top: 2px;
  background-blend-mode: #d3d3d3;
  display: none;
  pointer-events: none;
}
.delete {
  width: 1%;
  float: right;
  padding-right: 5%;
}
.iconDelete {
  color: #555;
  font-size: 25px;
  font-weight: 100;
  margin-top: 20px;

  cursor: pointer;
}
.catNoContainer {
  width: 94%;
  border-radius: 0% !important;
  border: 1px solid hsl(0, 0%, 80%) !important;
  display: flex;
  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 80%;
}

.catNoRag {
  width: 15%;

  background-color: #008000;
  float: left;
}
.catNoBody {
  height: 70px;
  width: 100%;
}
.catNoContent {
  width: 90%;
  float: left;
}
.catNoText {
  padding: 5px 5px 5px 10px;
  border: 1px solid #d3d3d3;
  margin: 10px 10px 10px 10px;
}

.selectedColor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
}

.colorOptions {
  margin-top: -75px;
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #ffffff;
  padding: 5px 5px 0px 5px;
  margin-left: 19px;
  border: 1px solid #000000;
}
.tip {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: 0px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid white;
}

.colorOption {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-bottom: 5px;
  cursor: pointer;
}
.catRagPrintOnly {
  display: none;
}
@media screen and (max-width: 1280px) {
  .arrow {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1200px) {
  .catNoContainer {
    min-height: 165px !important;
  }
}
@media screen {
  .cardsContainer {
    min-height: 320px !important;
  }
}
@media print {
  .arrow {
    font-size: 12px !important;
    /* margin-right:-2px !important; */
  }
  .cardsContainer {
    margin-top: 0px !important;
  }
  .pageBreakBefore {
    page-break-before: always;
  }
  .pageBreakAfter {
    page-break-after: always;
  }
  .catNoContainer {
    min-height: 220px !important;
  }

  .catRagReadOnly {
    display: flex;
  }
  .catRagPrintOnly {
    display: flex;
    width: 8%;
    margin-top: 5px !important;
    margin-right: 0.5%;
    /* background-color: #008000; */
    float: left;
  }
  .catNoContainer {
    margin-top: 15px !important;
  }
  .catRagReadOnly {
    /* display: none; */
    /* height:auto !important; */
    /* max-height: 350px !important; */
  }
  /* .catNoRag {
   height: 360px !important;
    background-color: #008000;
    float: left;
  } */
  /* .catNoBody {
    height: 70px;
    width: 100%;
  }
  .catNoContent {
    width: 90%;
    float: left;
  } */
}
