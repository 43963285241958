.report {
  width: 100%;
  /* padding-top: 2%; */
  display: flex;
  flex-flow: wrap;
}
.reportReadOnly {
  color: #555;
}
.reportNoAccess {
  /* background-color: #f1f1f1;
  opacity: 0.2; */
}
.reportContainer1 {
  width: 93%;
  display: flex;
  flex-flow: wrap;
  padding: 10px;

  margin-left: 7%;
}
.movableContainer1 {
  width: 100%;
  /* overflow-y: auto; */
  height: 80vh;
}
.reportContainer {
  width: 93%;
  display: flex;
  flex-flow: wrap;
  padding: 10px;
  margin-left: 6%;
  overflow-y: scroll;
  display: flex;

  /* Add the following styles to ensure that the container stays in place */
  position: fixed;
  /* overflow: hidden; */
}
.printContainer {
  width: 100%;
  display: flex;
  flex-flow: wrap;
}

.movableContainer {
  width: 100%;
  overflow-y: auto;

  /* height: 62vh; */

  /* Add the following style to ensure that the container does not extend beyond its parent */
  /* max-height: 100%; */
}
:global .slide-pane__overlay {
  z-index: 0 !important;
}
.metaContainer {
  width: 100%;
  float: left;
  font-size: 16px !important;
  padding-top: 25px;
}
.metaProject {
  width: 17%;
  float: left;
}
.projectIcon {
  margin-left: 180px;
  margin-top: 0px;

  cursor: pointer;
}
.projectIconView {
  margin-left: 180px;
  margin-top: 0px;

  display: none;
  pointer-events: none;
}
.projectIconAdd {
  margin-left: 0px;
  margin-top: 0px;

  cursor: pointer;
}
.metaCat {
  width: 83%;
  float: left;
}
.catLine {
  width: 20%;
  float: left;
}

.projectContainer {
  width: 100%;
}
.classContainer {
  width: 100%;
}
.metaLineProject {
  width: 100%;
  float: left;
  padding-top: 2%;
  padding-bottom: 0.5%;
  height: 10vh;
  min-height: 10vh;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;

  border: 6px solid transparent;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  height: 10px;
}
.leftNavBar {
  width: 5%;
  position: fixed;
  padding-top: 5%;
}
.content {
  width: 80%;
  margin-left: 0%;
  position: absolute;
}
.otherContent {
  width: 40%;
  padding: 20px 20px 20px 20px;

  text-align: center;
}
.headContainer {
  border-bottom: 1px solid #d3d3d3;
  width: 100%;
}
.head {
  text-align: center;
  width: 100%;
  font-size: 36px;
  margin-top: 1%;
  padding-left: 7%;
  float: left;
}
.noScreen {
  display: none;
}
.infoContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2.3%;
}
@media screen {
  .movableContainer {
    width: 100%;
    overflow-y: auto;
    height: 78vh;
    padding-bottom: 100px;
  }
}
.printHead {
  display: none;
}
/* @page {
  size: A4 landscape;
} */
@media print {
  .metaLine1 {
    padding-left: 34.8%;
    position: absolute;
  }
  .metaLine2 {
    padding-right: 0 !important;
    padding-left: 34.8%;
    position: absolute;
  }
  .metaLine3 {
    padding-right: 0 !important;
    padding-left: 34.8%;
    position: absolute;
  }
  .noScreen {
    display: block;
  }
  .headButtons,
  .progressBar {
    display: none !important;
  }
  .movableContainer,
  .metaContainer {
    padding-left: 3%;
    padding-right: 3%;
  }
  .metaContainer {
    padding-top: 2%;
  }
  .topLine {
    /* padding-top: 1% !important;
    margin-top:0em !important */
  }
  .printHead {
    display: flex;
    justify-content: flex-start;
    font-size: 18px;
    color: red;
    padding: 10px 10px 10px 10px;
  }
  /*.printContainer {
    page-break-after: always;
    width: 100%;
    display: table;
    .table-footer > tr > td {
      text-align: center;
      background-color: grey;
      color: black;
    } 
  }*/
  /* .pageFooter {
    position: fixed;
    z-index: 9;
    bottom: 0;
    width: 100%;
    height: 50px;
    font-size: 15px;
    color: #fff;
    background: red;
    opacity: 0.5;
    page-break-after: always;
  } */
  .head {
    font-size: 32px !important;
    padding-top: 0 !important;
  }
  .pageBreakBefore {
    page-break-before: always;
  }
  .pageBreakAfter {
    page-break-after: always;
  }
  .hLine {
    page-break-before: always;
  }
}
/* In your component's CSS or a separate stylesheet */
@page {
  size: A4 landscape;
  margin-bottom: 10mm;
  /* margin-top: 19mm; */
}

/* Add page numbers to each printed page */
/* @page :first {
  
  @bottom-left {
    content: "Page 1";
  }
}

@page {
  
  @bottom-left {
    content: "Page " counter(page);
  }
} */

.headTitle {
  float: left;
  margin-left: 10%;
}
.headDate {
  width: 27%;
  display: flex;
  float: left;
  margin-left: 1%;
  position: relative;
  z-index: 9999 !important;
}

.headDate input {
  width: 100%;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
}
/* Your existing styles for various elements */

/* Simplified styles for the Flexbox layout */
.headMetaContainer {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 90%;
  padding-left: 5%;
}

.metaTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 85%;
  align-items: center;
}
.metaItem {
  /* position: absolute;
  padding-left: 32.4%; */
}
.metaValID {
  font-weight: 600;
}
.metaLine1 {
  /* padding-right: 55.1%; */
}
.metaLine2 {
  padding-right: 46%;
}
.metaLine3 {
  padding-right: 46.8%;
}
.draft {
  color: #007bff;
}

.underReview {
  color: orange;
}

.completed {
  color: green;
}
.metaTopLeft,
.metaTopRight {
  display: flex;
  flex-direction: column;
  width: 48%; /* Adjust width as needed */
}

/* Your other styles for labels and values */
.metaField {
  font-weight: 600;
  padding-right: 10px;
}
.metaValStatusView {
  /* padding-left: 9.5%;
  color: orange; */
}
.metaValStatus {
  padding-bottom: 2px;
  padding-top: 0px;

  border: none;
  background: #2596be;
}
/* Add styles for common metaValue class */
.metaValue {
  /* Add your common styles for metaValue labels */
}

/* .metaField {
  font-weight: 600;
}
.metaValDate {
  padding-left: 1%;
}

.metaValType {
  padding-left: 1.5%;
}
.metaValGroup {
  padding-left: 10px;
}
.metaValAuthor {
  padding-left: 28px;
}

.headMetaContainer {
  width: 100%;
  font-size: 16px;
}

.metaTop {
  width: 100%;
  float: left;
}
.metaTopLeft {
  width: 50%;
  float: left;
}
.metaTopRight {
  padding-left: 3%;
  float: left;
}
.metaTopRightDD {
  margin-left: 5px;
} */

.projectLine {
  width: 18.5%;
  float: left;
}
/*.projectCard
{
  width: 18.5%;
  float:left;
  margin-left:-3%;
  margin-top:1%;
}*/

.metaLine {
  width: 100%;
  float: left;
}

.title {
}

.reportContent {
  width: 100%;
  position: absolute;
  margin-top: 20%;
  text-align: center;
}
.titleText {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;

  padding-left: 0px;
  font-style: normal;
}

.headButtons {
  /* width: 25%; */
  margin-top: -4%;
  float: right;
  /* margin-right: -20%; */
  cursor: pointer;
}
.iconTitle {
  color: #a5a5a5;
  margin-bottom: 6px;
  font-size: 40px;
  font-weight: 100;
}
.iconSave {
  color: rgb(255, 255, 255);
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: 100;
  cursor: pointer;
}
.iconDeleteReport {
  color: #fff;
  margin-bottom: 6px;
  font-size: 24px;
  font-weight: 100;
  cursor: pointer;
}
.button {
  position: relative;
  font-size: 18px !important;
  border-radius: 40px !important;
  color: rgb(255, 255, 255) !important;
  cursor: pointer !important;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
}
.buttonExport {
  position: relative;
  font-size: 18px !important;
  border-radius: 40px !important;
  color: rgb(255, 255, 255) !important;
  cursor: pointer !important;
  font-weight: bold;
  margin-left: 3em;
  text-align: center;
  overflow: hidden;
}
.btnDeleteReport {
  position: relative;
  font-size: 18px !important;
  border-radius: 40px !important;
  color: rgb(255, 255, 255) !important;
  background-color: #b71c1c !important;
  cursor: pointer !important;
  font-weight: bold;
  border: none;
  text-align: center;
  overflow: hidden;
}
.headLeftBox {
  display: flex;
  position: absolute;
  margin-top: 1em;
  width: auto;
}
.pdfContainer {
  display: none;
}
.button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  transform: translate(-50%, -50%);

  opacity: 0;
  transition: all 0.6s ease-out;
  z-index: -1;
}
.btnHide {
  display: none !important;
}
.btnSave::before {
  background: linear-gradient(54.7deg, #960ead 12%, #0ad8c7 100%);
}
.btnEdit::before {
  background: -webkit-linear-gradient(right, #52a0fd, #00e2fa);
}

.button:hover::before {
  opacity: 1;
  transform: translate(-50%, -50%) scale(2);
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.button:focus {
  outline: none;
}

.button:active {
  transform: translateY(0);
  box-shadow: none;
}

.btnSave {
  background-color: #1e7898 !important; /*#2596be */

  background-image: linear-gradient(to bottom, #1e7898, #2596be);
}
.btnEdit {
  background-color: #2596be !important; /*#2596be */

  background-image: linear-gradient(to bottom, #2596be, #1e7898);
}
.textSave {
  padding-left: 5px;
  cursor: pointer;
  font-weight: 400;
}
.hidden {
  position: absolute;
  opacity: 0;
}
.hLine {
  border-bottom: 2px dashed #888;
  width: 100%;
  display: flex;
  padding-top: 20px;
  margin-bottom: 2%;
}
.hLineBottom {
  border-bottom: 2px dashed #888;
  width: 100%;
  display: flex;
  padding-top: 10px;
}

.cLine {
  border-bottom: 2px dashed #888;
  width: 83%;
  margin-left: 17%;
  padding-top: 5px;
  margin-bottom: 10px;
}
.classHead {
  text-align: center;
  padding-left: 50%;
  color: #000000;
  font-weight: 600;
  /* padding-top: 5px; */
}
.prLine {
  border-bottom: 2px dashed #d3d3d3;
  width: 84%;
  display: flex;
  padding-top: 20px;
  margin-left: 16%;
  margin-bottom: 2%;
}
.topLine {
  border-bottom: 2px dashed #888;
  width: 100%;
  display: flex;
  width: 83%;
  margin-left: 17%;
  margin-top: 1em;
}
.graphContainer {
  width: 100%;
  font-size: 14px;
  /* position: relative; */
}

.infoLine {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.rectangle {
  width: 25px;
  height: 25px;
  margin-right: 1%;
}
.rectangleText {
  width: 25px;
  height: 25px;
  margin-right: 1%;
  font-size: 14px;
  color: grey;
}
.snackbar {
  width: 80%;
  max-width: none;
}
.impact {
  width: 25px;
  height: 25px;
  margin-right: 1%;
}

.infoLabel {
  flex: 0 1 20%;
  margin-left: 1%;
}
.plusDisabled {
  opacity: 0.3;
}

.iconAdd {
  color: #000000;
  font-size: 25px;
  margin-top: 2px;
  font-weight: 100;
  cursor: pointer;
}
.iconDelete {
  color: #555;
  font-size: 25px;
  font-weight: 100;
  margin-top: 20px;

  cursor: pointer;
}
.iconLink {
  color: #555;
  font-size: 30px;
  font-weight: 100;
  float: right;
  margin-top: 5px;
  cursor: pointer;
}

:global(.table) > :not(caption) > * > * {
  border-bottom-width: 0px !important;
}
.td {
  border-bottom-width: 0px !important;
}

.notesBoxDelete {
  width: 2%;
  height: 94px;
  padding-left: 1%;
  vertical-align: middle;
  padding-top: 40%;
  /* margin-left: -3.5%; */
  float: left;
}
.highlight {
  animation: shake 0.5s ease-in-out;
  border: none;
  box-shadow: 0 0 20px red; /* Narrow box shadow */
}
.disableEvents {
  pointer-events: none;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  80% {
    transform: translateX(10px);
  }
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  40%,
  60% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.progressBar {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 15px; /* Increased height */
  width: 100%;
  background-color: #e6e6e6;
}

.progress {
  position: relative;
  height: 100%;
  width: 0;
  transition: width 0.5s ease-in-out;
}

.progress.yellow {
  background-color: #ffa500; /* Yellow color */
}

.progress.green {
  background-color: #4caf50; /* Green color */
}

.progress.green ~ .message {
  opacity: 1;
}
.messageHide {
  visibility: hidden;
}

.successMsg {
  position: fixed;
  font-size: 18px;
  bottom: 20px;
  right: 30px;
  background-color: #52a0fd;
  color: white;
  padding: 10px;
  border-radius: 30px;
  z-index: 9999;
  visibility: hidden;
  visibility: visible;
}

@keyframes slide-in-out {
  0% {
    right: -200px;
  }
  10% {
    right: 20px;
  }
  90% {
    right: 20px;
  }
  100% {
    right: -200px;
  }
}

.Rcontainer {
  display: flex;
  flex-direction: column;
}

.selectedColor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
}

.colorOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.colorOption {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}
@media screen and (max-width: 1600px) {
  /* For Dell */
  .headButtons {
    /* margin-top: -4%; */
    float: right;
    /* margin-right: 0% !important; */
    cursor: pointer;
  }
  .head {
    font-size: 36px !important;
  }
}
@media screen and (max-width: 1745px) and (min-width: 1700px) {
  .metaLine1 {
    padding-left: 33.4%;
  }
}

@media screen and (max-width: 1280px) {
  /* For Zooms and surface */
  .head {
    font-size: 26px !important;
    margin-top: 4% !important;
  }
  .headMetaContainer,
  .metaContainer {
    font-size: 10px !important;
  }
  .iconSave,
  .textSave {
    font-size: 12px !important;
  }
  .metaContainer {
    padding-top: 4% !important;
  }
  .iconSave {
    margin-bottom: 0px !important;
    font-size: 12px !important;
  }
  .btnEdit {
    padding-top: 0px !important;

    height: 30px !important;
  }
  .btnEdit {
    width: 80px !important;
  }
  .buttonExport {
    padding-top: 0px !important;
    width: 130px !important;
  }
  .headButtons {
    margin-top: -8% !important;
  }
  .classHead {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1300px) {
  /* For Surface */
  .head {
    font-size: 26px !important;
    margin-top: 4% !important;
  }
  .metaContainer {
    padding-right: 2%;
  }
  .headButtons {
    margin-top: -8% !important;
    margin-right: -8% !important;
  }
  .metaLine1 {
    padding-left: 34.8%;
    position: absolute;
  }
  .metaLine2 {
    padding-right: 0 !important;
    padding-left: 34.8%;
    position: absolute;
  }
  .metaLine3 {
    padding-right: 0 !important;
    padding-left: 34.8%;
    position: absolute;
  }
  .iconContainer {
    margin-left: 3em;
  }
  .prActions {
    margin-top: 0.2em;
  }
  .projectIcon {
    cursor: pointer;
    margin-left: 110px;
  }
}
@media screen and (min-width: 1900px) {
  /* For Large Monitor */

  .reportContainer {
    margin-top: 0% !important;
  }
  .leftNavBar {
    padding-top: 5%;
  }
}
.metaContainerPdf {
  width: 100%;
  float: left;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 16px !important;
}
.reportCard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-columns: 1fr 1fr 1fr auto;  */
  row-gap: 0;
  border: none;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-left: 9%;
  width: 100%;
  margin-top: 5em;
}
/* styles.module.css */
tr {
  scroll-margin-top: 100px; /* Applies to ALL <tr> elements globally */
}
.row {
  display: contents; /* To maintain the grid layout for each row */
}
.columnButtons {
  display: flex;
  margin-left: 10%;
  align-items: center;
}

.topButton {
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-width: 80px;
}

.column {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
}

.labelCol1 {
  font-weight: 600;
  margin-right: 5px;
  width: 80px;
}
.labelCol2 {
  font-weight: 600;
  margin-right: 5px;
  width: 100px;
}
.labelCol3 {
  font-weight: 600;
  margin-right: 5px;
  width: 110px;
}

.reportId {
  color: #007bff;
  text-decoration: none;
  font-weight: 700;
}

.statusButton,
.reportTypeButton {
  background-color: #2596be;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 3px 8px;
  cursor: pointer;
  width: 100px;
  text-align: center;
}
.statusView,
.reportTypeView {
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 3px 8px;
  cursor: pointer;
  width: 100px;
  text-align: center;
}
.statusButton:hover,
.reportTypeButton:hover {
  background-color: #138496;
}
.labelBJ {
  color: #fff;
  font-size: 18px;
  font-family: Calibri;
  margin-bottom: 10px !important;
  background-color: #aa3939;
  text-transform: uppercase !important;
}
.bjDropdown {
  background-color: #fff !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
.deleteDocContainer {
  background-color: #aa3939;
  overflow: hidden !important;
}
.paperStyle {
  bottom: 10%;
  width: 900px !important;

  margin-top: 200px;
  max-width: unset !important;
  background-color: #4c4c4c !important;
  padding: 10px 10px 10px 10px;
}
.disabledButton {
  opacity: 0.5;
  pointer-events: none; /* Ensure the button is unclickable */
}

.deleteDialog {
  margin-bottom: 0px;
  padding: 10px 10px 10px 10px;
}
.deleteSpacer {
  background-color: #4c4c4c; /* Background color */
  height: 20px;
  width: 120%;
  margin-left: -10%;
  margin-top: 20px;
}

.bj {
  margin-top: 20px !important;
}
.asterikBJ {
  color: #fff;
  padding-left: 2px;
}
.textBJ {
  font-family: Calibri;
  background-color: #fff;

  border: 1px solid #333;
}
.deleteText {
  color: #000 !important;
  font-family: Calibri !important;
  font-size: 18px !important;
}

.deleteTitle {
  color: #2596be !important;
  font-family: Calibri !important;
  font-size: 24px !important;
}
.deleteTitleHead {
  text-decoration: underline;
  cursor: pointer;
  padding-left: 10px;
}
.closeIcon {
  color: #fff;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
}
.deleteTitleDoc {
  background-color: #aa3939;
  color: white;
  font-weight: bold;
  padding: 10px !important;
  text-align: center;
  padding-right: 2em !important;
  font-size: 28px !important;
}

.deleteTextDoc {
  color: #fff !important;
  background-color: #aa3939;
  display: flex;
  align-items: center;
  font-family: Calibri !important;
  font-size: 20px !important;
  white-space: pre-line;
}

.dialogConfirmButtonDoc {
  background-color: #aa3939 !important;
  color: white !important;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  margin-right: 10px !important;
  font-family: Calibri !important;
}

.dialogCancelButtonDoc {
  background-color: #ffffff !important;
  color: #333 !important;
  font-family: Calibri !important;
  padding: 5px 10px 5px 10px !important;
  border: 1px solid #d3d3d3 !important;
}
