body {
  overflow-y: auto;
}

.head {
  text-align: center;
  width: 100%;
  font-size: 42px;
  margin-top: -1%;
  padding-right: 6%;
  padding-top: 3%;
}
.disabled {
  pointer-events: none;
  background-color: #f1f1f1;
  opacity: 0.2;
}

.reportBoxes {
  text-align: center;
  width: 100%;
  padding-left: 5%;
  margin-top: 2%;
}
.reportBoxButton {
  width: 70%;
  background-color: #e3e3e3;
  border: 3px solid;
  color: black;
  height: 100px;
  border-radius: 0%;
  margin-top: 1%;
  cursor: pointer;
}
.contentBoxIndRC {
  border-color: #000000 !important;
}
.contentBoxIndRF {
  border-color: #0047ab !important;
}
.contentBoxIndCF {
  border-color: green !important;
}
.contentBoxIndNF {
  border-color: orange !important;
}
.contentBoxIndPR {
  border-color: lightcoral !important;
  pointer-events: none;
  background-color: #f1f1f1;
  opacity: 0.2;
}

.contentBox {
  width: 80%;
  margin-top: -6%;
}

.reportBox {
  width: 100%;
}

.reportButtonPad {
  margin-bottom: 5%;
}

.reportBoxTextHead {
  font-size: 28px;
  text-align: center;
}
.reportBoxTextSub {
  font-size: 18px;
  margin-top: 0%;
}

/* @media(min-width: 1224px) {
  .reportBoxTextSub {
    font-size: 18px;
    margin-top: 15%;
  }
} */
/*Tiled styles*/
.home {
  width: 100%;
  padding-top: 1%;
  display: grid;
}
.homeTile {
  width: 93%;
  float: left;
  padding-left: 10%;
}
.homeContainer {
  display: grid;
  width: 90%;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding-left: 15%;
  padding-top: 2%;
}

.cardContainer {
  border: 3px solid #000000 !important;
  border-radius: 20px;
  margin-top: 2%;
  text-align: center;
  height: 320px;
  cursor: pointer;
  width: 95%;
  grid-column: span 1; /* Each card occupies 1 column width */
}
.naContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  /* Add more styles as needed */
}

.naMessage {
  padding: 16px;
  background-color: #f0f0f0;
  border-radius: 8px;
  /* Add more styles as needed */
}
.naMessage a {
  cursor: pointer;
}
.na h2 {
  font-size: 24px;
  /* Add more styles as needed */
}


.leftNavBar {
  width: 5%;
  position: fixed;
  padding-top: 5%;
}
.navBar :global {
  width: 50px;
  padding-top: 10% !important;
}
.iconTile {
  width: 30%;
  float: left;
}
.tileImage {
  width: 250px;
  height: 215px;
}
.iconBox {
  /* width: 250px;
 position: absolute;
 display: flex; */
  /*padding-top: 2%; */
  height: 190px;
  background-color: #e9f5f9;
  border-radius: 20px;
  padding-top: 2%;
  border-bottom-left-radius: 0px 0px;
  border-bottom-right-radius: 0px 0px;
}
.cardText {
  margin-top: 2%;
  text-align: center;
}
.cardHead {
  background-color: #1e7898 !important;
  border: none !important;
  border-radius: 0px !important;
  margin-right: 5%;
  font-size: 18px !important;
}

.cardSub {
  padding-left: 1em;
  padding-top: 1em;
  font-size: 16px;
}
.exReportImg {
  margin-top: 1%;
}
.bottomRow {
  margin-left: 15%;
}
.chatSection {
  margin-left: 20px;
  padding-bottom: 20px;
}
/* App clases*/
.header {
  background: #2596be;
  width: 100%;

  position: fixed;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1600px) {
  /* For Dell */
  .homeContainer {
    grid-template-columns: repeat(5, 1fr);
    padding-left: 10%;
    padding-top: 5%;
  }

  .cardSub {
    font-size: 12px;
  }
  .head {
    padding-top: 5%;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1200px) {
  /* For Surface */

  .slideBox {
    margin-left: 10%;
  }
  .headerImage {
    padding-top: 0 !important;
  }
  .header {
    height: 5.5em;
  }
  .cardSub {
    font-size: 12px;
  }
  .head {
    padding-top: 5%;
  }

  .tileImage {
    width: 230px;
    height: 215px;
  }

  .homeContainer {
    padding-left: 8%;
  }
}
@media screen and (min-width: 1900px) {
  /* For Large Monitor */
  .cardContainer:nth-child(4),
  .cardContainer:nth-child(5) {
    margin-left: 30%;
  }

  .cardContainer {
    margin-bottom: 5%;
  }
}

.headerImage {
  width: 10%;
  background: #2596be;

  padding-left: 2%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

.headerText {
  padding-top: 1%;
  text-align: center;
  padding-right: 15%;
  background: #2596be;
  color: #ffffff;
  font-size: 15px;
  flex-grow: 1;
}

.headerSubText {
  font-weight: 400;
  font-size: 16px;
}

.user {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 2%;
  height: 100px;
}

.profile {
  margin-right: 1em;
  display: flex;
}

.login {
  margin-right: 1em;
}

.signOutButton {
  display: inline-flex;
  background-color: transparent !important ;
  color: #ffa500 !important;
  border: none !important;
  cursor: pointer;
}
.signOutIcon {
  vertical-align: middle;
  font-size: 25px;
}
.signInButton {
  display: inline-flex;
  background-color: transparent !important ;
  color: #ffffff !important;
  border: none !important;
  cursor: pointer;
}
.signInIcon {
  vertical-align: middle;
  font-size: 25px;
}

.authenticatingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.authenticatingText {
  font-size: 24px;
  text-align: center;
  color: #333;
}
.profileCard {
  display: flex;
  align-items: center;

  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 4px #ffff;
}

.userPhoto {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.userName {
  font-size: 16px;

  color: #ffffff;
}
