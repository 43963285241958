.container {
  display: flex;
  flex-direction: column;

  justify-content: center;
  margin-left: 20%;
  max-width: 80%;
  padding-top: 5%;
}

.deletingText {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 20px;
  transition: color 0.5s ease-in-out;
}

.blinking {
  animation: blink 1s step-end infinite;
  margin-left: -50px !important;
  color: green;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.highlight {
  color: #ff0000;
  font-weight: 400;
}

.redirectText {
  padding-top: 10px;
  padding-left: 20px;
}

.progressBarContainer {
  width: 100%;
  height: 50px;
  background-color: #484c57;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 7%;
  max-width: 80%;
}
.progressHead {
  padding-left: 10%;
}
.progressBar {
  height: 100%;
  background-color: #04a4b8;
  transition: width 0.1s linear;
}
.recoveryTip {
  display: flex;
  flex-direction: column; /* Ensure that the text appears below the title */
  align-items: flex-start;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 5px;
  text-align: left;
  margin-top: 10%;
  max-width: 60%;
  margin-left: 10%;
}

.iconAndTitle {
  display: flex;
  align-items: center; /* Align icon and title on the same line */
  margin-bottom: 10px; /* Space between title and text */
}

.recoveryIcon {
  height: 50px !important;
  margin-right: 15px;
  width: 50px !important;
}

.recoveryTitle {
  font-weight: 600 !important;
}

.recoveryText {
  margin-top: 0; /* Ensure the text aligns nicely with the title */
}

.link {
  color: blue;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
