.navBar1 {
  width: 200px;
  height: 100px;
  background-color: #ccc;
  border-right: 5px solid blue;
  transform: skewX(-10deg);
  margin-top: 20px;

  transform: skewX(-10deg);
}
.homeIcon {
  margin-left: 12px;
  color: #2596be;
  font-size: 60px !important;
  cursor: pointer;
  margin-bottom: 15px;
}
.disabled {
  pointer-events: none;
  background-color: #f1f1f1;
  opacity: 0.2;
}
.navBar {
  width: 50px;
}
.iconContainer {
  position: relative;
  display: inline-block;
}

.redX {
  position: absolute;
  top: 55%;
  left: 30%;
  width: 30px; /* Adjust size as needed */
  height: 30px;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.redX::before,
.redX::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px; /* Thickness of the X */
  background-color: red;
  top: 60%;
  left: 0;
}

.redX::before {
  transform: rotate(45deg);
}

.redX::after {
  transform: rotate(-45deg);
}

.icons {
  width: 70px;
  height: 70px;
}
@media screen and (max-width: 1280px) {
  .icons {
    width: 40px !important;
    height: 40px !important;
  }
  .hLine {
    width: 40px !important;
  }
  .navBar::before {
    top: 11.5% !important;
    left: 100%;
  }
  .homeIcon {
    width: 0.6em !important;
    height: 0.6em !important;
  }
}
.selected {
  /* background-color: red;
  padding-right:10px; */
  /* box-shadow: -25px 0 0 red; */
}

.navBar::before {
  content: "";
  position: absolute;
  top: 0.1%;
  /* 6.9% */
  left: 90%;
  height: 100vh;
  width: 1px;
  background-color: #bddce9;
}
/*1280, 1920, 1556*/

@media screen and (max-width: 1600px) {
  /* For Dell */
  .navBar::before {
    top: 2.7%;
    left: 100%;
  }
  .navBar {
    padding-top: 5rem;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1200px) {
  /* For Surface */
  .navBar::before {
    top: 8.9%;
    left: 110% !important;
  }
  .navBar {
    padding-top: 5rem;
  }
}

@media screen and (min-width: 2100px) {
  /* For Large Monitor */
  .navBar::before {
    top: 0%;
    left: 100%;
  }
}
@media screen and (max-width: 1025px) {
  /* For Surface */
  .navBar::before {
    top: 7.9%;
    left: 130%;
  }
  .navBar {
    padding-top: 5rem;
  }
}
@media (min-width: 1800px) and (max-width: 1900px) {
  /* For Surface Pro */

  .navBar::before {
    top: 0% !important;
  }
}
.hLine {
  border-bottom: 2px dotted #000000;
  width: 60px;

  margin-left: 10px;
  margin-bottom: 20px;
}

.vLine {
  border-left: 1px solid #000000;
  height: 100vh;
  width: 1%;
  margin-top: -900%;
  margin-left: 80%;
}
