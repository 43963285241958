.slide-pane {
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 100px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s;
  will-change: transform;
}
:global(.slide-pane) {
  width: 40% !important;
}
:global(.slide-pane__close) {
  margin-left: 12px !important;
}
:global(.slide-pane__header) {
  background-color: #1e7898 !important;
  color: #fdfeff;
}
.slideBox {
  margin-left: 15%;
}

@media screen and (max-width: 1380px) and (min-width: 1200px) {
  /* For Surface */

  .slideBox {
    margin-left: 10%;
  }
}
@media screen and (max-width: 1600px) {
  .reportBoxTextSub {
    padding-left: 5% !important;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1200px) {
  /* For Surface */

  .slideBox {
    margin-left: 10%;
  }
  .reportBoxTextSub {
    padding-left: 5% !important;
    font-size: 14px !important;
  }
}
.cardText {
  margin-top: -10%;
  text-align: center;
}
.slideBoxButton {
  width: 95%;
  background-color: #e3e3e3;
  border: 3px solid;
  color: black;
  height: 100px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10%;
}
.slidePane {
  background-color: #1e7898;
}
.slideHead {
  text-align: center;
  width: 100%;
  font-size: 42px;
  margin-top: 5%;
  margin-bottom: 15%;
}
.cardsContainer {
  width: 40%;
}

.cardContainerEx {
  width: 45%;
  margin-left: 4%;

  float: left;
  border: 3px solid orange !important;
  margin-top: 20%;

  height: 400px;
}
.cardContainer {
  width: 45%;
  margin-left: 4%;

  float: left;
  border: 3px solid green !important;
  border-radius: 10px;
  margin-top: 20%;
  text-align: center;
  height: 400px;
}
.cardSub {
  padding-top: 20px;
  font-size: 18px;
}
.cardSubEx {
  padding-top: 20px;
  font-size: 18px;
}
.cardImage {
  height: 285px;
  width: 300px;
  image-rendering: -webkit-optimize-contrast;
}
.cardImageEx {
  height: 285px;
  width: 300px;
  padding-top: 12px;
  padding-bottom: 12px;
  image-rendering: -webkit-optimize-contrast;
}
.cardTitle {
  background-color: #1e7898 !important;
}
.cardTitleEx {
  background-color: #1e7898;
  margin-top: 22px;
}
.reportBoxTextHead {
  font-size: 28px;
  text-align: center;
  padding-top: 2%;
}
.reportBoxTextSub {
  font-size: 16px;
  margin-top: 0%;
  padding-left: 20%;
}
.contentBoxIndNR {
  border-color: green !important;
}
.contentBoxIndER {
  border-color: orange !important;
}

.slide {
  width: 40%;
}
.slide-pane:focus {
  outline-style: none;
}
.slide-pane_from_right {
  margin-left: auto;
  transform: translateX(100%);
}
.slide-pane_from_right.content-after-open {
  transform: translateX(0%);
}
.slide-pane_from_right.content-before-close {
  transform: translateX(100%);
}
.slide-pane_from_left {
  margin-right: auto;
  transform: translateX(-100%);
}
.slide-pane_from_left.content-after-open {
  transform: translateX(0%);
}
.slide-pane_from_left.content-before-close {
  transform: translateX(-100%);
}
.slide-pane_from_bottom {
  height: 90vh;
  margin-top: 10vh;
  transform: translateY(100%);
}
.slide-pane_from_bottom.content-after-open {
  transform: translateY(0%);
}
.slide-pane_from_bottom.content-before-close {
  transform: translateY(100%);
}
.slide-pane__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.slide-pane__overlay.overlay-after-open {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s;
}
.slide-pane__overlay.overlay-before-close {
  background-color: rgba(0, 0, 0, 0);
}
.slide-pane__header {
  display: flex;
  flex: 0 0 64px;
  align-items: center;
  background: red !important;
  height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.slide-pane__title-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 32px;
  min-width: 0;
}
.slide-pane .slide-pane__title {
  font-size: 18px;
  font-weight: normal;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}
.slide-pane__close {
  margin-left: 24px;
  padding: 16px;
  opacity: 0.7;
  cursor: pointer;
}
.slide-pane__close svg {
  width: 12px;
  padding: 0;
}
.slide-pane__content {
  position: relative;
  overflow-y: auto;
  padding: 24px 32px;
  flex: 1 1 auto;
}
.slide-pane__subtitle {
  font-size: 12px;
  margin-top: 2px;
}
