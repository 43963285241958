.blockedNote {
  pointer-events: none !important;
  color: #ff726f;
}
.blockIcon
{
  font-size: 20px;
  padding: 0 5px 0 0;
}
.linkboxModal {
  margin-left: 11%;
  position: absolute;
  width: 45%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  background-color: #eaeaea;
  border: 1px dotted #ccc;
  padding: 10px;
  border-radius: 5px;
  height: 270px;
  overflow-y: auto;
  margin-top: -10%;
}
.linkbox {
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  background-color: #eaeaea;
  padding: 5px 5px 0px 5px;
  margin-left: 2%;
  margin-top: -60%;
  min-height: 100px;
  width: 200px;
  border: 1px dotted #ccc;
  padding: 10px;
  border-radius: 5px;
  height: 270px;
  overflow-y: auto;
}
.linkbox::before {
  content: "";
  position: absolute;
  top: 48px; /* Move the beak slightly down */
  right: calc(
    100% - 0px
  ); /* Adjust the right position to position the beak properly */
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #ccc;
  border-left: none;
  z-index: 1; /* Ensure the beak is on top of the linkbox container */
}

.linkbox::after {
  content: "";
  position: absolute;
  top: 49px; /* Move the beak slightly down */
  right: calc(
    100% - 1px
  ); /* Adjust the right position to position the beak properly */
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #eaeaea;
  border-left: none;
  z-index: 1; /* Ensure the beak is on top of the linkbox container */
}
.linkboxModal::before {
  content: "";
  position: absolute;
  top: 48px; /* Move the beak slightly down */
  right: calc(
    100% - 0px
  ); /* Adjust the right position to position the beak properly */
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #ccc;
  border-left: none;
  z-index: 1; /* Ensure the beak is on top of the linkbox container */
}

.linkboxModal::after {
  content: "";
  position: absolute;
  top: 49px; /* Move the beak slightly down */
  right: calc(
    100% - 1px
  ); /* Adjust the right position to position the beak properly */
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #eaeaea;
  border-left: none;
  z-index: 1; /* Ensure the beak is on top of the linkbox container */
}
/* LinkBox.module.css */

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  position: relative;
  border-radius: 5px;
}

.row::after {
  content: "";
  position: absolute;
  bottom: 0px; /* Adjust the bottom position to set the dotted line distance */
  left: 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #ccc;
}
.txtNote {
  padding: 0 2px 0 5px;
}
.unlocked {
  background-color: #fdfca3;
  cursor: pointer;
}

.lockIcon {
  cursor: pointer;
  font-size: 20px;
  padding: 0 5px 0 0;
}
@media screen and (max-width: 1600px) {
  .linkbox {
    margin-left: 4% !important;
    margin-top: -24% !important;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1200px) {
  .linkbox {
    margin-left: 4% !important;
    margin-top: -24% !important;
  }
}
