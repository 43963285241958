.crContainer {
  width: 94%;
  border-radius: 0% !important;
  /* height: 195px; */
  border: 1px solid hsl(0, 0%, 80%) !important;
  /* width:19%;
  border-radius: 0% !important;
  font-size:14px;
  float:left;te
  height: 280px;*/
  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 3%;
}

.crRag {
  height: 113%;
  width: 15%;
  margin-top: -8.5%;
  margin-left: -8%;
  background-color: #008000;
  float: left;
  float: left;
  position: relative;
}
.paletteContainer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.palette {
  font-size: 24px !important;
  color: #fff;
}
.crContent {
  height: inherit;
  width: 90%;
  float: left;
  padding-left: 2px;
  page-break-before: auto;
}
.crContentView {
  height: inherit;
  width: 97%;
  float: left;
  padding-left: 2px;
  page-break-before: auto;
}
.crDates {
  margin-left: 50px;
}
.crBodyEdit {
  height: 300px !important;
}
.crBody {
  height: auto;
  width: 100.5%;
  margin-left: 4%;
  min-height: 190px;
}
.crBody::before {
  content: "";
  height: 100.5%;
  width: 12%;
  background-color: #008000;
  position: absolute;
  top: -0.5%;
  left: 0;
  z-index: 1;
}
.crBodyRed {
  height: auto;
  width: 100.5%;
  margin-left: 3%;
  min-height: 190px;
}
.crBodyRed::before {
  content: "";
  height: 100.5%;
  width: 12%;
  background-color: #ff4500;
  position: absolute;
  top: -0.5%;
  left: 0;
  z-index: 1;
}
.crBodyOrange {
  height: auto;
  width: 100.5%;
  margin-left: 3%;
  min-height: 190px;
}
.crBodyOrange::before {
  content: "";
  height: 100.5%;
  width: 12%;
  background-color: #ffa500;
  position: absolute;
  top: -0.5%;
  left: 0;
  z-index: 1;
}
.iconArrow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; /* Set a higher z-index to place the icon on top of the pseudo-element */
  background-color: #fff; /* Background color for the icon */
  padding: 5px;
  border-radius: 50%;
}
.crTop {
  height: 15px;
  /* margin-bottom: 5px; */
  text-align: right;
  margin-right: -10%;
  padding-left: 5px;
}
.crId {
  color: #b0b0b0;
  font-size: 10.5px;
  display: flex;
  float: left;
  margin-top: -5%;
}

@media screen and (max-width: 1600px) {
  /* For Dell */
  .crId {
    font-size: 8px;
  }
  .crLine {
    display: none;
  }

  .crRag {
    height: 112% !important;
    width: 15%;
    margin-top: -10%;
    margin-left: -10%;
    background-color: #008000;
    float: left;
  }
  .crTopIcons,
  .crTopIconsView {
    margin-top: -20% !important;
    font-size: 14px !important;
  }

  .iconContainer {
    margin-left: 4em;
  }
  .crLabelExCol {
    width: 70px !important;
    font-size: 11px !important;
  }
  .colEx {
    display: flex;
    padding-left: 20px !important;
  }
  .colExView {
    display: flex;
    padding-left: 5px !important;
    padding-top: 5px !important;
  }
  .iconColEx {
    color: #555;
    font-size: 20px;
    font-weight: 100;
    margin-left: -7px !important;
    cursor: pointer;
  }
  .colDelete {
    display: flex;
    padding-left: 20px !important;
    margin-right: -5px !important;
  }
  .colorOptions {
    margin-left: 25px !important;
  }
}
@media screen {
  .crDesc {
    height: 190px !important;
    /* z-index: 999;
    position:relative; */
    /* overflow-y: scroll; */
  }
}
@media screen and (min-width: 1680px) and (max-width: 1850px) {
  .crRag {
    margin-left: -9% !important;
    height: 112% !important;
  }
  .colorOptions {
    margin-left: 29px !important;
  }

  .iconContainer {
    margin-left: 6em !important;
  }
}
@media screen and (max-width: 1280px) {
  .crId {
    font-size: 6px !important;
  }
  .crContainer {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1200px) {
  /* For Surface */

  .noteContainerView {
    margin-left: -17px !important;
  }
  .noteContainer {
    margin-left: -7px !important;
  }
  .colInfo {
    padding-right: 5px;
  }
  .crId {
    font-size: 6px;
  }
  .crRag {
    height: 112.5%;
    width: 15%;
    margin-top: -12%;
    margin-left: -12%;
    background-color: #008000;
    float: left;
  }
  .crTopIcons,
  .crTopIconsView {
    margin-top: -20% !important;
    font-size: 14px !important;
  }

  .iconContainer {
    margin-left: 4em;
  }
  .colBottom {
    margin-top: 10% !important;
  }

  .iconinfoEx {
    font-size: 15px !important;
  }
  .iconDelete {
    font-size: 15px !important;
  }
  .crLabelExCol {
    width: 70px !important;
    font-size: 11px !important;
  }
  .colEx {
    display: flex;
    padding-left: 0px !important;
  }
  .iconColEx {
    color: #555;
    font-size: 20px;
    font-weight: 100;
    margin-left: -7px !important;
    cursor: pointer;
  }
  .colDelete {
    display: flex;
    padding-left: 20px !important;
    margin-right: -5px !important;
  }
}
.crTopIcons {
  color: #555;
  margin-top: -15%;
  font-size: 22px;
  margin-right: 5px;
  font-weight: 100;
  cursor: pointer;
}
.crTopIconsView {
  color: #d3d3d3;
  margin-top: -15%;
  font-size: 22px;
  margin-right: 15px;
  font-weight: 100;
  cursor: pointer;
}
.crTopIcon {
  width: 35%;
  float: left;
}
select option[data-default] {
  color: #888;
}
.crLabel {
  margin-top: 5px;
  font-weight: 600;
}

.crControls {
  padding-left: 8px;
  font-style: normal;
  width: 103%;
}
.crHeader {
  width: inherit;
  font-size: 12px;
}
.crHeaderDef {
  width: inherit;
  font-size: 12px;
  color: #888;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
}
.crDesc {
  /* width: inherit; */
  padding-top: 5px;
  font-size: 12px;

  /* height: 80px !important;
  overflow-y: scroll; */
}
.crDescView {
  width: inherit;
  padding-top: 5px;
  font-size: 12px;
  /* height: 80px !important;
  overflow-y: scroll; */
}
.arrow {
  /* z-index: 999;
  color: #ffffff;
  margin-top: 10px;
  margin-left: 3px; */
  font-size: 30px !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; /* Set a higher z-index to place the icon on top of the pseudo-element */
  color: #fff; /* Background color for the icon */
  padding: 5px;
  border-radius: 50%;
}

.upArrow,
.downArrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.upArrow {
  border-width: 0 6px 8px 6px;
  border-color: transparent transparent #fff transparent;
  top: -8px; /* Adjust the positioning as needed */
  left: 50%; /* Center the arrow horizontally */
  transform: translateX(-50%); /* Center the arrow horizontally */
}

.downArrow {
  border-width: 8px 6px 0 6px;
  border-color: #fff transparent transparent transparent;
  bottom: -8px; /* Adjust the positioning as needed */
  left: 50%; /* Center the arrow horizontally */
  transform: translateX(-50%); /* Center the arrow horizontally */
}
.modifiedWord {
  color: #000000;
  display: inline-flex;
}

.unchangedWord {
  display: inline-flex;
}

.crDesc .crCopy {
  color: #b0b0b0;
}
.crCopy {
  color: #b0b0b0 !important;
}
.crCopy [class$="-singleValue"] {
  color: #b0b0b0;
}
.crAreaDesc {
  height: 190px !important;
}
.crDesc > textarea {
  max-height: 180px !important;
}
.iconTitle {
  color: #a5a5a5;
  margin-top: 6px;
  font-size: 20px;
  font-weight: 100;
  position: absolute;
}

.crDescArea {
  height: 100px;
  overflow: auto;
  resize: none;
  word-wrap: break-word;
  max-width: 180px;
  font-size: 12px;
  text-align: left;
}

.colBottom {
  margin-top: 5%;
  display: flex;
  width: 200px;
}
.colEx {
  display: flex;
  padding-left: 5px;
}
.colInfo {
  display: flex;
  padding-left: 2px;
}
.colDelete {
  display: flex;
  padding-left: 40px;
  margin-right: -10px;
}
.iconColEx {
  color: #555;
  font-size: 20px;
  font-weight: 100;
  margin-left: 5px;
  cursor: pointer;
}
.iconinfoEx {
  color: #555;
  font-size: 20px;
  font-weight: 100;
  cursor: pointer;
}
.iconDelete {
  color: #ff726f;
  font-size: 20px;
  font-weight: 100;
  cursor: pointer;
}
.titleSpan {
  position: absolute;
  opacity: 0;
  /* word-wrap:break-word;
max-width: 160px; */
}
.crLabelExCol {
  font-size: 12px;
}

.colorOptions {
  margin-top: 3px;
  position: absolute;
  z-index: 1200;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #ffffff;
  padding: 5px 5px 0px 5px;
  margin-left: 29px;
  margin-top: -90px;
  border: 1px solid #000000;
}
.tip {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: 12px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid white;
}

.crNameView {
  word-break: break-word;
  height: 40px;
  font-weight: 600;
}
.colorOption {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-bottom: 5px;
  cursor: pointer;
}
.crviewDesc {
  overflow: scroll;
  /* height:165px !important; */
}
.noteContainer {
  position: absolute;
  margin-left: -3px;
  padding-top: 10px;
}
.noteContainerView {
  margin-left: -13.5px;
  position: absolute;
  z-index: 999;
  padding-top: 10px;
}
.noteOnTop {
  color: #fff !important;
  display: block;
  font-weight: 600 !important;
  cursor: pointer;
  text-decoration: none !important;
  font-size: 18px !important;
  height: 50px;
}
@media print {
  .crAreaDesc {
    height: auto !important;
    overflow: none !important;
  }
  .crTop,
  .colBottom {
    display: none;
  }

  .arrow {
    font-size: 25px !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; /* Set a higher z-index to place the icon on top of the pseudo-element */
    color: #fff; /* Background color for the icon */
    padding: 5px;
    padding-left: 0px !important;
    border-radius: 50%;
    margin-left: 0px !important;
  }
  .crNameView {
    height: auto !important;
  }
  .crContainer {
    margin-top: 1em !important;
  }

  .crLine {
    border-bottom: 1px solid #888;
    padding-top: 5px;
    width: 100%;
  }
  .crBody,
  .crBodyOrange,
  .crBodyRed {
    min-height: 320px !important;
  }
  .pageBreakBefore {
    page-break-before: always;
  }
  .pageBreakAfter {
    page-break-after: always;
  }
}
