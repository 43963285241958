.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f2f2f2;
  padding: 10px;
  padding-left: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  width: 100%;
}

.state {
  margin-right: 16px;
  display: flex;
  align-items: center;
  padding-right: 40px;
  border: none !important;
}

.metaArea {
  margin-top: 8px;
}

.tabs {
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: flex-end;
  margin-left: 90%;
}
.label {
  padding-right: 40px;
  padding-top: 2px;
  color: #888;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
}
.headText {
  font-size: 14px;
}
.colorCircle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 8px;
}

.colorCircleHistory {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  /* Additional styles for the color circle */
}
.deleted {
  text-transform: uppercase;
  color: #ff0000;
  font-weight: 700;
  padding-left: 100px;
}
.tab {
  margin-left: 16px;
  cursor: pointer;
}

.active {
  font-weight: bold;
  background-color: #ddd;
}

.cmdModalArea {
  max-width: 80% !important;
  background-color: #ffffff !important;
}
.cmdModalAreaFull {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.cmdModalAreaFull :global .modal-content {
  border-radius: 0;
  height: 100vh !important;
  overflow-y: hidden !important;
}
@media screen and (max-width: 1600px) {
  /* For Dell */
  .cmdModalArea {
    margin-top: -6% !important;
  }
  .cmdModalAreaFull :global .modal-content {
    height: 650px !important;
  }
  .headTop {
    padding-left: 0% !important;
  }
}
@media screen and (max-width: 1380px) and (min-width: 1200px) {
  /* For Surface */
  .cmdModalArea {
    height: 700px !important;
    margin-top: 10% !important;
  }
  .cmdModalAreaFull :global .modal-content {
    height: 650px !important;
  }
  .headTop {
    padding-left: 0% !important;
  }
}
@media screen and (min-width: 1900px) {
  /* For Large Monitor */
  .cmdModalArea {
    margin-top: 0% !important;
  }
  .cmdModalAreaFull :global .modal-content {
    height: 800px !important;
  }
}
.cmHeaderViewTitle {
  font-size: 26px;
}

.cmdModalArea :global .modal-content {
  height: 800px;
  margin-top: 6%;
  overflow-y: auto;
  border-radius: 0% !important;
}
:global .modal-header {
  border-bottom: none !important;
  padding-left: 0 !important;
}
:global .btn-close {
  margin-top: -5% !important;
}
:global .modal-body {
  padding: 0 !important;
}
:global .modal-footer {
  border-top: none !important;
}

.cmHeaderDef {
  width: inherit;
  font-size: 12px;
  color: #888;
  cursor: pointer;
}
.area {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2%;
  padding-left: 2%;
}

.leftArea {
  width: 50%;

  box-sizing: border-box;
}

.headTop {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  padding-left: 30%;
  padding-top: 4%;
  width: 50%;
  margin-bottom: -1%;
}

.iconCmd {
  margin-right: 10px;
  vertical-align: middle;
  color: #555;
  cursor: pointer;
  font-size: 20px;
}
.iconCmdView {
  margin-right: 10px;
  vertical-align: middle;
  color: #d3d3d3;
  cursor: pointer;
  font-size: 20px;
  pointer-events: none;
}
.iconTop {
  margin-right: 10px;

  vertical-align: middle;
  color: #555;
  cursor: pointer;
  font-size: 20px;
}
.modalHeader {
  width: 100%;
  display: flex;
}
.titleBlockPainter {
  display: flex;
  width: 10px;
  background-color: green;
  float: left;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 145px;
  /* margin-top: -1.2%;
  margin-bottom: -1.2%; */
}
.titleBlock {
  padding-left: 20px;
  width: 50%;
  float: left;
}
.iconinfoEx {
  color: #555;
  vertical-align: middle;
  display: inline-block;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 100;
  cursor: pointer;
  margin-top: -2px;
}
.text {
  font-weight: 400;
}
.title {
  font-size: 28px;
  font-weight: 400;

  float: left;
  padding-left: 0px;
}
.identifier {
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  float: left;
  padding-bottom: 10px;
}
.saveButton {
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  padding: 6px 12px;
  cursor: pointer;
}

.saveButton:hover {
  background-color: #0069d9;
}

.saveButtonIcon {
  margin-right: 5px;
}

.descArea,
.noteArea,
.historyArea,
.ragArea {
  margin-bottom: 20px;
}
.descArea {
  height: 300px;
  overflow-y: auto;
}
.history {
  overflow-y: auto;
  height: 200px;
}

.rightHeader {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.rightSeparator {
  border-top: 2px solid #007bff;
  margin-top: 5px;
  margin-bottom: 20px;
}

.rightText {
  font-size: 16px;
}
.cmdTextDisabled {
  pointer-events: none;
  height: 40px;
  position: relative;
  width: 600px;
  font-size: 12px;
}

.historyArea {
  margin-bottom: 20px;
}

.separator {
  border-top: 1px solid #999;
  margin-top: 5px;
  margin-bottom: 10px;
}

.historyItem {
  padding: 10px 0;
}

.time {
  font-size: 14px;
  font-weight: normal;
}

.historyDetails {
  font-size: 14px;
}

.modified {
  font-weight: 400;
}

.field,
.value {
  font-weight: 600;
}
.updated {
  font-weight: 400;
}
.rightArea {
  width: 40%;
  height: 100%;
  display: flex;
  margin-left: 10%;
  flex-direction: column;
  justify-content: space-between;
}

.ragArea {
  margin-bottom: 20px;
  margin-right: 5px;
}

.ragSeparator {
  border-top: 2px dashed #f2fafc;
  margin: none !important;
}

.areaHeader {
  font-size: 24px;
  font-weight: 400;
  display: inline-block;

  vertical-align: middle;
}

.lineHead {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.scrollable {
  /* overflow-y: auto; */
  height: 450px;
  padding: 10px;
  position: relative;
}

.scrollable::-webkit-scrollbar {
  width: 8px;
  right: 0;
}

.scrollable::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable::-webkit-scrollbar-thumb {
  background: #888;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.item {
  display: flex;
  align-items: center;

  padding-top: 10px;
  padding-left: 10px;
}

.leftSide {
  flex-grow: 0.5;
  margin-right: 40px;
}

.heading {
  font-size: 16px;
  font-weight: 400;
}

.value {
  font-style: normal;
  font-weight: 600;
}
.ragValueProject {
  font-style: normal;
  font-weight: 400;
  padding-left: 2em;
}
.ragValueHeader {
  font-style: normal;
  font-weight: 400;
  padding-left: 1.9em;
}
.ragValueCat {
  font-style: normal;
  font-weight: 400;
  padding-left: 1.1em;
}
.ragValueRag {
  font-style: normal;
  font-weight: 400;
  padding-left: 3.3em;
}
.ragValueId {
  font-style: normal;
  font-weight: 400;
  padding-left: 3.3em;
}
.ragItem {
  background-color: #f2fafc;
  margin-right: 10px;
  cursor: pointer;
}
.ragItemExternal {
  background-color: #f2fafc;
  margin-right: 10px;
}
.ragItem {
  background-color: #f2fafc;
  margin-right: 10px;
  cursor: pointer;
}
.arrow {
  display: flex;
  align-items: center;
  color: #555;
  position: absolute;
  align-items: center;
  left: 80%;
  margin-top: 10px;
}
